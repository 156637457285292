import { useFrame } from '$shared/utils';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import LeftArrow from '$icons/arrow-left.svg';
import { N35MobileMegaMenu } from '~/features/navigation';
import { N31MegaMenu } from '~/features/navigation/components/N31MegaMenu';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { useTranslation } from '~/shared/utils/translation';
import { useMobileMenu } from '../N35MobileMegaMenu/hooks/useMobileMenu';
import { MiniBasketButton, MobileMegaMenuButton, AvatarButton } from './components';
import { N26SearchBarNew } from '../N26SearchBarNew/N26SearchBarNew';
import {
    StyledMainContent,
    StyledContent,
    StyledDesktopNav,
    StyledLogoContainer,
    StyledMainNavigation,
    StyledMobileMenuHeader,
    StyledMetaMenu,
    StyledSearchContainer,
    StyledMobileSearchContainer,
    StyledN21NavigationIndicatorWrapper,
    StyledIconsWrapperMobile,
    StyledIconsWrapperDesktop,
    StyledLogoWrapperMobile,
    StyledLogoWrapperDesktop,
    StyledBackArrow,
    StyledMobileMegaMenuWrapper,
    StyledLeftArrow,
    StyledMetaButton,
    StyledSearchText,
} from './styled';
import { weakKey } from '~/shared/utils/jsx';
import { Link, RouterLink, Logo, VisuallyHidden } from '~/shared/components';
import { ClubOverlays } from '~/features/club';
import { useTheme } from '@emotion/react';
import { useThemeShade } from '~/theme';
import { N70NavigationIndicator } from '../N70NavigationIndicator';
import { usePage } from '$templates/pages';
import { useRouter } from 'next/router';
import { useSearchBarUIState } from '../../hooks/search/useSearchBarUIState';
import Search from '$icons/solidAsh/search.svg';

const N21MainMenu = memo(() => {
    const { data } = useFrame();
    const { mainMenu = [], metaMenu = [] } = data?.navigation || {};
    const { isSearchEmphasized } = useSearchBarUIState();

    const { isOpen, activeMenuState } = useMobileMenu();
    const activeMenuHeader = activeMenuState?.node?.link?.text;
    const { translate } = useTranslation();
    const [activeNodeId, setActiveNodeId] = useState<string | null>();
    const [pathBeforeSearchPageState, setPathBeforeSearchPageState] = useState<
        string | undefined
    >();
    const { traits } = useTheme();
    const { type: pageType } = usePage();
    const isSearchPage = pageType === ('m170searchPage' as unknown);
    const router = useRouter();

    const { backgroundColor, textShade, readableColorShade } = useThemeShade({
        backgroundColor: traits.background.n21mainMenu,
    });

    const shouldLogoBeInverted = useMemo(() => readableColorShade === 'light', [
        readableColorShade,
    ]);

    useEffect(() => {
        setActiveNodeId(null);
    }, [activeMenuState]);

    const onGoBack = useCallback(() => {
        setActiveNodeId(activeMenuState.parentNode?.id ?? '');
    }, [activeMenuState.parentNode?.id]);

    const setPathBeforeSearchPage = () => {
        setPathBeforeSearchPageState(router.asPath);
    };

    const redirectBackToSavePath = () => {
        router.push(pathBeforeSearchPageState || '');
    };

    const showActiveMenu = isOpen && activeMenuHeader;
    const BackArrow = ({ onClick }: { onClick: () => void }) => {
        return (
            <StyledLeftArrow onClick={onClick}>
                <LeftArrow aria-hidden="true" title="Go back" />
                <VisuallyHidden children={translate('accessibility.goBack')} />
            </StyledLeftArrow>
        );
    };

    return (
        <>
            <StyledMainNavigation
                backgroundColor={backgroundColor}
                textShade={textShade}
                isSearchPage={isSearchPage}
            >
                <StyledContent isSearchPage={isSearchPage}>
                    <StyledLogoContainer>
                        <StyledLogoWrapperMobile md>
                            {!isSearchPage && (
                                <>
                                    {showActiveMenu && <BackArrow onClick={onGoBack} />}
                                    {!showActiveMenu && (
                                        <Logo inverted={shouldLogoBeInverted} mobileInverted />
                                    )}
                                </>
                            )}

                            {isSearchPage && (
                                <>
                                    {showActiveMenu && <BackArrow onClick={onGoBack} />}
                                    {!showActiveMenu && (
                                        <StyledBackArrow onClick={redirectBackToSavePath}>
                                            <LeftArrow aria-hidden="true" title="Go back" />
                                            {translate('navigation.back')}
                                        </StyledBackArrow>
                                    )}
                                </>
                            )}
                        </StyledLogoWrapperMobile>
                        <StyledLogoWrapperDesktop md>
                            <Logo inverted={shouldLogoBeInverted} />
                        </StyledLogoWrapperDesktop>
                    </StyledLogoContainer>

                    <StyledSearchContainer md>
                        <N26SearchBarNew />
                    </StyledSearchContainer>

                    <StyledMetaMenu>
                        {metaMenu.map(
                            (link) =>
                                link.url && (
                                    <li key={weakKey(link)}>
                                        <Link
                                            href={link.url}
                                            text={link.text}
                                            textShade={textShade}
                                            textVariant="bodySm"
                                            {...link}
                                        />
                                    </li>
                                )
                        )}
                    </StyledMetaMenu>
                </StyledContent>

                {isOpen && activeMenuHeader ? (
                    <StyledMobileSearchContainer md>
                        <StyledMobileMenuHeader
                            children={activeMenuHeader}
                            isSearchPage={isSearchPage}
                        />
                    </StyledMobileSearchContainer>
                ) : (
                    !isSearchPage &&
                    !isSearchEmphasized && (
                        <StyledMobileSearchContainer md>
                            <RouterLink href={data?.staticLinks?.searchPage?.url ?? ''}>
                                <StyledMetaButton
                                    as="a"
                                    onClick={setPathBeforeSearchPage}
                                    style={{ flex: 1 }}
                                >
                                    <Search />
                                    <StyledSearchText variant={'bodySm'} isSmall>
                                        {translate('header.search.placeholder.mobile')}
                                    </StyledSearchText>
                                </StyledMetaButton>
                            </RouterLink>
                        </StyledMobileSearchContainer>
                    )
                )}

                <StyledMainContent isSearchPage={isSearchPage}>
                    <StyledDesktopNav>
                        <N31MegaMenu navigation={mainMenu} isLightHeader />
                    </StyledDesktopNav>

                    <StyledIconsWrapperMobile>
                        <AvatarButton />
                        <MiniBasketButton />
                        <MobileMegaMenuButton isLight={isSearchPage} />
                    </StyledIconsWrapperMobile>

                    <StyledIconsWrapperDesktop>
                        <AvatarButton />
                        <MiniBasketButton />
                        <MobileMegaMenuButton />
                    </StyledIconsWrapperDesktop>
                </StyledMainContent>

                <StyledMobileMegaMenuWrapper>
                    <N35MobileMegaMenu
                        activeNodeByUrl={false}
                        activeNodeId={activeNodeId}
                        isLightHeader
                    />
                </StyledMobileMegaMenuWrapper>
                <StyledN21NavigationIndicatorWrapper>
                    <N70NavigationIndicator />
                </StyledN21NavigationIndicatorWrapper>
            </StyledMainNavigation>
            <ClubOverlays />
        </>
    );
});

N21MainMenu.displayName = 'N21MainMenu';

export default withErrorBoundary(N21MainMenu);
